























import Vue from 'vue'
import { EditableTheme } from '../../../types'
import { mapMutations } from 'vuex'
import { MUTATIONS } from '../../../store/consts'
import ThemeImageUpload from '../../ThemeImageUpload.vue'
import { IAppModuleThemable } from '@simpl/core/plugins/app-modules'

type ThemeImageInputType = { src: string, file: File }

export default Vue.extend({
  components: {
    ThemeImageUpload
  },

  data () {
    return {
      useAppBarLogo: false
    }
  },

  computed: {
    theme (): EditableTheme {
      return this.$store.state.theme.theme
    },
    appModules (): IAppModuleThemable[] {
      return this.$appModules
        .themables()
        .sort((a, b) => (a.order || 0) - (b.order || 0))
    }
  },

  watch: {
    theme: {
      handler (v: EditableTheme, o?: EditableTheme) {
        if (v.id === o?.id) {
          return
        }
        this.useAppBarLogo = !!v.appBarLogoUrl
      },
      immediate: true
    }
  },

  methods: {
    ...mapMutations('theme', {
      updateThemeAppModule: MUTATIONS.UPDATE_THEME_APP_MODULE,
      setHoveredAppModule: MUTATIONS.SET_HOVERED_APP_MODULE
    }),
    onImageUpdate (key: string, input?: ThemeImageInputType) {
      const { src = null, file = null } = input || {}

      this.updateThemeAppModule({
        key,
        url: src,
        localFile: file
      })
    },

    onEnterUploadElement (key: string) {
      this.setHoveredAppModule(key)
    },
    onLeaveUploadElement () {
      this.setHoveredAppModule()
    }
  }
})
