import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=fdd53ac8&"
import script from "./Sidebar.vue?vue&type=script&lang=ts&"
export * from "./Sidebar.vue?vue&type=script&lang=ts&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VExpansionPanel,VExpansionPanelContent,VExpansionPanels,VFadeTransition,VNavigationDrawer,VProgressLinear,VSheet,VSpacer,VSwitch})
