





















































import Vue from 'vue'
import { resizeImage } from '../utils'

export default Vue.extend({
  model: {
    prop: 'src'
  },

  props: {
    src: {
      type: String,
      default: undefined
    },
    maxSize: {
      type: Number,
      default: undefined
    },
    favicon: Boolean
  },

  methods: {
    onInput (e: InputEvent) {
      const files = (e.target as HTMLInputElement).files

      const file = files?.[0]

      if (!file) return

      if (this.maxSize) {
        resizeImage({
          file,
          maxSize: this.maxSize
        }).then((blob) => {
          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified
          })
          const src = URL.createObjectURL(resizedFile)
          this.$emit('input', { src, file: resizedFile })
        })
      } else {
        const src = URL.createObjectURL(file)
        this.$emit('input', { src, file })
      }
    },
    onRemove () {
      this.$emit('input')
    }
  }
})
