







































import Vue from 'vue'

const RE_HEX_COLOR = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

export default Vue.extend({
  model: {
    prop: 'color'
  },

  props: {
    color: String,
    label: String
  },

  data () {
    return {
      maskData: {
        mask: '#FFFFFF',
        tokens: {
          F: {
            pattern: /[0-9a-fA-F]/
          }
        }
      },
      menu: false,
      rules: {
        color: (value: string) => RE_HEX_COLOR.test(value)
      }
    }
  },

  computed: {
    proxyColor: {
      get (): string {
        return this.color
      },
      set (v: string) {
        this.$emit('input', v)
      }
    },
    swatchStyle (): Partial<CSSStyleDeclaration> {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.6)',
        marginTop: '-3px'
      }
    }
  }
})
