import { EditableTheme, EditableThemeAppModule } from '../types'
import {
  StoreThemeInput,
  Theme,
  ThemeVariables,
  File as GraphQLFile,
  AppModuleThemeInput
} from '@simpl/core/types/graphql'
import { uuid } from '@simpl/cms-components/utils/uuid'
import { app } from '@simpl/core/init-app'
import { apolloProvider } from '@simpl/core/plugins/apollo'
import { UPLOAD_FILE } from '@simpl/core/graphql'
import store from '@simpl/core/plugins/store'

type ThemeDefaults = {
  variables: ThemeVariables,
  logoUrl?: string
}

export function themeToEditableTheme (theme: Theme): EditableTheme {
  return {
    id: theme.id,
    dark: !!theme.dark,
    active: !!theme.active,
    preferred: !!theme.preferred,
    colors: {
      primary: theme.colors.primary,
      secondary: theme.colors.secondary,
      accent: theme.colors.accent,

      success: theme.colors.success,
      info: theme.colors.info,
      warning: theme.colors.warning,
      error: theme.colors.error
    },
    variables: {
      text: theme.variables.text,
      sheetBackground: theme.variables.sheetBackground,
      background: theme.variables.background
    },
    fontUrl: theme.font?.url || undefined,
    useDifferentFontForHeading: !!theme.font?.url && (theme.font?.url !== theme.font_headings?.url),
    fontHeadingUrl: theme.font_headings?.url || undefined,
    logoUrl: theme.logo?.url || undefined,
    appBarLogoUrl: theme.app_bar_logo?.url || undefined,
    welcomeImageUrl: theme.welcome_image?.url || undefined,
    faviconImageUrl: theme.favicon?.url || undefined,
    appModules: theme.app_modules.reduce<Record<string, EditableThemeAppModule>>((acc, m) => {
      acc[m.key] = {
        url: m.file?.url || undefined
      }
      return acc
    }, {})
  }
}

export function createEditableTheme (dark: boolean): EditableTheme {
  const themeColors = { ...app.$vuetify.theme.themes[dark ? 'dark' : 'light'] }
  delete themeColors.anchor

  const defaults: ThemeDefaults = dark
    ? {
        variables: {
          text: '#fff',
          background: '#1c1c1c',
          sheetBackground: '#2a2b2d'
        }
      }
    : {
        variables: {
          text: '#111',
          background: '#f5f5f5',
          sheetBackground: '#ebebeb'
        }
      }

  const otherTheme = store.state.theme.remoteThemes.find((theme: Theme) => !!theme.logo)
  if (otherTheme?.logo) {
    defaults.logoUrl = otherTheme.logo.url
  }

  return {
    id: uuid(),
    dark,
    ...defaults,
    active: false,
    preferred: false,
    colors: { ...themeColors as any },
    useDifferentFontForHeading: false,
    appModules: {}
  }
}

export async function uploadThemeAssets (
  theme: Record<string, any>,
  onProgress: (uploaded: number, total: number) => void,
  onCompleted: () => void,
  remoteTheme?: Record<string, any>
): Promise<Partial<StoreThemeInput>> {
  const keys: Record<string, string> = {
    localFontFile: 'font_id',
    localFontHeadingFile: 'font_headings_id',
    localLogoFile: 'logo_id',
    localAppBarLogoFile: 'app_bar_logo_id',
    localWelcomeImageFile: 'welcome_image_id',
    localFaviconImageFile: 'favicon_id'
  }

  const result: Record<string, any> = {}

  let count = 0
  const filesToUpload = Object.keys(keys).filter(key => !!theme[key])
  for (const key in keys) {
    const outputKey = keys[key]
    const remoteThemeKey = keys[key].replace('_id', '')
    const file: File = theme[key]

    // if file was reset/deleted
    if (file === null) {
      result[outputKey] = null
      continue
    }

    // if file value was never changed
    if (typeof file === 'undefined') {
      if (remoteTheme?.[remoteThemeKey]?.id) {
        const prevId = remoteTheme[remoteThemeKey].id
        result[outputKey] = typeof prevId === 'undefined' ? undefined : parseInt(prevId)
      } else {
        result[outputKey] = null
      }
      continue
    }

    const response = await apolloProvider.defaultClient.mutate<{ uploadFiles: GraphQLFile[] }>({
      mutation: UPLOAD_FILE,
      variables: {
        file: {
          selected_file: file,
          name: file.name,
          target_type: 'theme_asset'
        }
      }
    })

    if (response.data?.uploadFiles?.[0].id) {
      result[keys[key]] = parseInt(response.data.uploadFiles[0].id)
    }

    count++

    onProgress(count, filesToUpload.length)
    onCompleted()
  }

  return result as any
}

export async function uploadThemeAppModuleImages (
  theme: EditableTheme,
  onProgress: (uploaded: number, total: number) => void,
  onCompleted: () => void,
  remoteTheme?: Theme
): Promise<AppModuleThemeInput[]> {
  const result: AppModuleThemeInput[] = []

  let count = 0
  const filesToUpload = Object.keys(theme.appModules).filter(key => !!theme.appModules[key].localFile)
  for (const key in theme.appModules) {
    const appModule = theme.appModules[key]
    const file: File | null | undefined = appModule.localFile

    // if file was reset/deleted
    if (file === null) {
      result.push({
        key,
        file_id: null
      })
      continue
    }

    // if file value was never changed
    if (typeof file === 'undefined') {
      const prevAppModule = remoteTheme?.app_modules.find(m => m.key === key)
      if (prevAppModule?.file) {
        result.push({
          key,
          file_id: prevAppModule.file.id
        })
      }
      continue
    }

    const response = await apolloProvider.defaultClient.mutate<{ uploadFiles: GraphQLFile[] }>({
      mutation: UPLOAD_FILE,
      variables: {
        file: {
          selected_file: file,
          name: file.name,
          target_type: 'theme_asset'
        }
      }
    })

    if (response.data?.uploadFiles?.[0].id) {
      result.push({
        key,
        file_id: response.data.uploadFiles[0].id
      })
    }

    count++

    onProgress(count, filesToUpload.length)
    onCompleted()
  }

  return result
}

export function editableThemeToStoreThemeInput (theme: EditableTheme, domainId: number): StoreThemeInput {
  return {
    domain_id: String(domainId),
    dark: theme.dark,
    active: theme.active,
    preferred: theme.preferred,
    colors: { ...theme.colors } as any,
    variables: { ...theme.variables } as any
  } as any
}

interface IResizeImageOptions {
  maxSize: number;
  file: File;
}
export const resizeImage = (settings: IResizeImageOptions): Promise<Blob> => {
  const file = settings.file
  const maxSize = settings.maxSize
  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')
  const dataURItoBlob = (dataURI: string) => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1])
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const max = bytes.length
    const ia = new Uint8Array(max)
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
    return new Blob([ia], { type: mime })
  }
  const resize = () => {
    let width = image.width
    let height = image.height

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width
        width = maxSize
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height
        height = maxSize
      }
    }

    canvas.width = width
    canvas.height = height
    canvas.getContext('2d')!.drawImage(image, 0, 0, width, height)
    const dataUrl = canvas.toDataURL('image/jpeg')
    return dataURItoBlob(dataUrl)
  }

  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Not an image'))
      return
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => resolve(resize())
      image.src = readerEvent.target.result
    }
    reader.readAsDataURL(file)
  })
}
