export const ACTIONS = {
  GET_THEMES: 'getThemes',
  SAVE_THEME: 'saveTheme'
}

export const MUTATIONS = {
  SET_MODES: 'setModes',

  SET_THEMES: 'setThemes',
  SET_THEME: 'setTheme',

  UPDATE_THEME_PROP: 'updateThemeProp',
  UPDATE_THEME_APP_MODULE: 'updateThemeAppModule',

  INCREMENT_LOADING: 'incrementLoading',
  DECREMENT_LOADING: 'decrementLoading',

  SET_SWITCHING_THEME: 'setSwitchingTheme',
  SET_HOVERED_APP_MODULE: 'setHoveredAppModule'
}
