import { render, staticRenderFns } from "./ThemeImageUpload.vue?vue&type=template&id=0c271574&scoped=true&"
import script from "./ThemeImageUpload.vue?vue&type=script&lang=ts&"
export * from "./ThemeImageUpload.vue?vue&type=script&lang=ts&"
import style0 from "./ThemeImageUpload.vue?vue&type=style&index=0&id=0c271574&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c271574",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFadeTransition,VIcon,VSpacer})
