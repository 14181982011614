





























































import Vue from 'vue'
import { EditableTheme } from '../../../types'
import { mapMutations } from 'vuex'
import { MUTATIONS } from '../../../store/consts'
import ThemeImageUpload from '../../ThemeImageUpload.vue'

type ThemeImageInputType = { src: string, file: File }

export default Vue.extend({
  components: {
    ThemeImageUpload
  },

  data () {
    return {
      useAppBarLogo: false
    }
  },

  computed: {
    theme (): EditableTheme {
      return this.$store.state.theme.theme
    }
  },

  watch: {
    theme: {
      handler (v: EditableTheme, o?: EditableTheme) {
        if (v.id === o?.id) {
          return
        }
        this.useAppBarLogo = !!v.appBarLogoUrl
      },
      immediate: true
    }
  },

  methods: {
    ...mapMutations('theme', { updateThemeProp: MUTATIONS.UPDATE_THEME_PROP }),
    onLogoUpdate (input?: ThemeImageInputType) {
      const { src = null, file = null } = input || {}

      this.updateThemeProp({
        logoUrl: src,
        localLogoFile: file
      })
    },
    onAppBarLogoUpdate (input?: ThemeImageInputType) {
      const { src = null, file = null } = input || {}

      this.updateThemeProp({
        appBarLogoUrl: src,
        localAppBarLogoFile: file
      })
    },
    onWelcomeImageUpdate (input?: ThemeImageInputType) {
      const { src = null, file = null } = input || {}

      this.updateThemeProp({
        welcomeImageUrl: src,
        localWelcomeImageFile: file
      })
    },
    onFaviconImageUpdate (input?: ThemeImageInputType) {
      const { src = null, file = null } = input || {}

      this.updateThemeProp({
        faviconImageUrl: src,
        localFaviconImageFile: file
      })
    }
  }
})
