







































import Vue from 'vue'
import ColorInput from '../ColorInput.vue'
import { EditableTheme } from '../../../types'

export default Vue.extend({
  components: {
    ColorInput
  },

  data () {
    return {
      colorKeys: ['primary', 'secondary', null, 'success', 'info', 'warning', 'error'],
      variableKeys: ['text', null, 'background', 'sheetBackground']
    }
  },

  computed: {
    switching (): boolean {
      return this.$store.state.theme._switching
    },
    theme (): EditableTheme {
      return this.$store.state.theme.theme
    }
  }
})
