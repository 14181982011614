
























































































import Vue from 'vue'
import PanelHeader from './PanelHeader.vue'
import SidebarAreaAppearance from './areas/SidebarAreaAppearance.vue'
import SidebarAreaColors from './areas/SidebarAreaColors.vue'
import SidebarAreaFonts from './areas/SidebarAreaFonts.vue'
import SidebarAreaImages from './areas/SidebarAreaImages.vue'
import { mapMutations } from 'vuex'
import { MUTATIONS } from '../../store/consts'
import { EditableTheme } from '../../types'
import { debounce, objectHash } from '@simpl/core/utils'
import SidebarAreaDashboard from './areas/SidebarAreaDashboard.vue'

export default Vue.extend({
  components: {
    PanelHeader
  },

  props: {
    uploading: Boolean,
    progress: Number
  },

  data () {
    return {
      linear: false,
      panel: 0,
      areas: [{
        key: 'appearance',
        completed: true,
        component: SidebarAreaAppearance
      }, {
        key: 'colors',
        completed: false,
        component: SidebarAreaColors
      }, {
        key: 'images',
        completed: false,
        component: SidebarAreaImages
      }, {
        key: 'fonts',
        completed: false,
        component: SidebarAreaFonts
      }, {
        key: 'dashboard',
        completed: false,
        component: SidebarAreaDashboard
      }],
      hasChanged: false,
      canSave: false
    }
  },

  computed: {
    isLightMode: {
      get (): boolean {
        return !this.$store.state.theme.theme?.dark
      },
      set (v: boolean) {
        this.setSwitchingTheme(true)

        const theme = this.$store.state.theme.themes.find((t: EditableTheme) => t.dark === !v)
        this.setTheme(theme)

        this.setSwitchingTheme()
      }
    },
    hasModeToggle (): boolean {
      return this.$store.state.theme.themes.filter((theme: EditableTheme) => theme.active).length > 1
    }
  },

  watch: {
    panel (v: number) {
      this.$emit('area-changed', this.areas[v].key)
    },
    '$store.state.theme.themes': {
      handler () {
        this.checkIfChanged()
        this.checkIfCanSave()
      },
      deep: true
    },
    '$store.state.theme.theme': 'checkIfCanSave',
    '$store.state.theme.originalThemes': 'checkIfChanged'
  },

  methods: {
    ...mapMutations('theme', {
      setThemes: MUTATIONS.SET_THEMES,
      setTheme: MUTATIONS.SET_THEME,
      setSwitchingTheme: MUTATIONS.SET_SWITCHING_THEME
    }),

    reset () {
      this.setThemes(this.$store.state.theme.remoteThemes)
    },

    checkIfChanged: debounce(function (this: any) {
      this.hasChanged = objectHash(this.$store.state.theme.themes) !== objectHash(this.$store.state.theme.originalThemes)
    }, 50),
    checkIfCanSave: debounce(function (this: any) {
      this.canSave = !this.$store.state.theme.themes.filter((theme: EditableTheme) => !theme.logoUrl)?.length
    }, 50)
  }
})
