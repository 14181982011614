


















































import Vue from 'vue'
import FileUploader from '@simpl/core/components/uploader/FileUploader.vue'
import { EditableTheme } from '../../../types'
import { mapMutations } from 'vuex'
import { MUTATIONS } from '../../../store/consts'

export default Vue.extend({
  components: {
    FileUploader
  },

  data () {
    return {
      useDefault: true
    }
  },

  computed: {
    theme (): EditableTheme {
      return this.$store.state.theme.theme
    },
    useDifferentHeadlineFont: {
      get (): boolean {
        return this.theme?.useDifferentFontForHeading || false
      },
      set (v: boolean) {
        this.updateThemeProp({
          useDifferentFontForHeading: v
        })
      }
    }
  },

  watch: {
    theme (v: EditableTheme, o?: EditableTheme) {
      if (v.id === o?.id) {
        return
      }
      this.useDefault = v && !v.fontHeadingUrl && !v.fontUrl
      this.useDifferentHeadlineFont = !!v.useDifferentFontForHeading
    }
  },

  methods: {
    ...mapMutations('theme', { updateThemeProp: MUTATIONS.UPDATE_THEME_PROP }),
    onFontUpdate (file?: File) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.updateThemeProp({
            fontUrl: e.target?.result as string,
            localFontFile: file
          })
        }
        reader.readAsDataURL(file)
      } else {
        this.updateThemeProp({
          fontUrl: null,
          localFontFile: null
        })
        this.useDifferentHeadlineFont = false
      }
    },
    onHeadingFontUpdate (file?: File) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.updateThemeProp({
            fontHeadingUrl: e.target?.result as string,
            localFontHeadingFile: file
          })
        }
        reader.readAsDataURL(file)
      } else {
        this.updateThemeProp({
          fontHeadingUrl: null,
          localFontHeadingFile: null
        })
      }
    }
  }
})
