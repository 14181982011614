






































import Vue from 'vue'
import { Theme } from '@simpl/core/types/graphql'
import { mapMutations } from 'vuex'
import { MUTATIONS } from '../../../store/consts'

export default Vue.extend({
  data () {
    return {
      defaultMode: 'light'
    }
  },

  computed: {
    themes (): Theme[] {
      return this.$store.state.theme.themes
    },
    canSelectPreferredTheme (): boolean {
      return this.themes.filter((theme: Theme) => theme.active).length > 1
    },
    preferredTheme: {
      get (): string {
        return this.themes.find((theme: Theme) => theme.preferred)?.dark ? 'dark' : 'light'
      },
      set (v: string) {
        const dark = v === 'dark'

        this.themes.forEach((theme: Theme) => {
          theme.preferred = theme.dark === dark
        })
      }
    }
  },

  watch: {
    themes: {
      deep: true,
      handler (v) {
        const activeThemes = v.filter((theme: Theme) => theme.active)
        if (activeThemes.length > 1) return

        this.preferredTheme = activeThemes[0].dark ? 'dark' : 'light'
        this.setTheme(activeThemes[0])
        this.setSwitchingTheme()
      }
    }
  },

  methods: {
    ...mapMutations('theme', {
      setModes: MUTATIONS.SET_MODES,
      setThemes: MUTATIONS.SET_THEMES,
      setTheme: MUTATIONS.SET_THEME,
      setSwitchingTheme: MUTATIONS.SET_SWITCHING_THEME
    }),

    canDeactivate (theme: Theme) {
      return theme.active && this.themes.filter((theme: Theme) => theme.active).length < 2
    }
  }
})
